import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlSelectDeviceAttributeModel } from '@common-src/model/form-control';
import { EnergyType } from '@common-src/model/enum';
import { SelectDeviceAttributeModel } from '@common-src/entity-model/device-entity';
import { kilo } from '@common-src/filter';
import { QueryPageModel } from '@common-src/model/query-model';

export class EnergyEntityModel extends BaseEntityModel {
    constructor(type: EnergyType) {
        super();
        this.type = type;
        this.deviceAttributeList = [new SelectDeviceAttributeModel()];
    }
    type: EnergyType;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '关联设备属性',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        multiple: true,
        scopeId: 'energy',
        required: true
    } as FormControlSelectDeviceAttributeModel)
    deviceAttributeList: Array<SelectDeviceAttributeModel> = undefined;
    val: any = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '电量(kWh)',
                dataIndex: 'val',
                customRender: (text, record, index) => {
                    return kilo(text);
                }
            },
            {
                title: '更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        const attributeList = _.get(json, 'deviceAttributeList');
        if (attributeList && attributeList.length > 0) {
            this.deviceAttributeList = _.map(attributeList, item => new SelectDeviceAttributeModel().toModel(item));
        } else {
            this.deviceAttributeList = [new SelectDeviceAttributeModel()];
        }
        return this;
    }

    toService() {
        const data = super.toService();
        data.deviceAttributeList = _.map(this.deviceAttributeList, item => {
            return {
                deviceId: item.deviceId,
                attr: item.key
            };
        });
        delete data.type;
        delete data.val;
        return data;
    }
}

export class EnergyQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data = {
            params: {
                name: this.name
            }
        };
        return data;
    }
}
