
import { EnergyEntityModel, EnergyQueryModel } from '@common-src/entity-model/energy-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import { EnergyType } from '@common-src/model/enum';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/energyItem`;

export class EnergyService implements ICRUDQ<EnergyEntityModel, EnergyQueryModel> {
    private type: EnergyType;

    constructor(type?: EnergyType) {
        this.type = type;
    }

    async create(model: EnergyEntityModel):Promise<EnergyEntityModel> {
        const url = `${URL_PATH}/${this.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<EnergyEntityModel> {
        const url = `${URL_PATH}/${this.type}/detail/${modelId}`;
        const res = await get(url);
        return new EnergyEntityModel(this.type).toModel(res);
    }

    async update(model: EnergyEntityModel):Promise<EnergyEntityModel> {
        const url = `${URL_PATH}/${this.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: EnergyEntityModel):Promise<EnergyEntityModel> {
        const url = `${URL_PATH}/${this.type}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: EnergyQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/${this.type}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new EnergyEntityModel(this.type).toModel(item));
        return res;
    }

    async getEnergyConfig() {
        const url = `${BI_BASE_REQUEST_PATH}/stats/get/DHC`;
        const res = await get(url);
        return res;
    }

    async setEnergyConfig(param: any) {
        const url = `${BI_BASE_REQUEST_PATH}/stats/save`;
        const res = await post(url, param);
        return res;
    }

    async getBuildingDeviceAttributeScopeConfig() {
        const url = `${BI_BASE_REQUEST_PATH}/energyItem/building/getDeviceConfigAttr`;
        const res = await get(url);
        return res;
    }

    async setBuildingDeviceAttributeScopeConfig(param: Array<{deviceTypeId: string, attr: string}>) {
        const url = `${BI_BASE_REQUEST_PATH}/energyItem/building/saveDeviceAttr`;
        const res = await post(url, param);
        return res;
    }
}
